<template>
  <div class="create-content-main">
    <div class="create-form">
      <div class="cpy-2">
        <span class="cpx-4 font-title">SNS設定</span>
      </div>
      <form
        class="cpx-15"
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            FACEBOOK
          </label>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">Enable</label>
          <div
            class="custom-control custom-switch col-sm-6 ml-4"
            style="height: 32px"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
              role="switch"
              v-model="enable_Facebook"
            />
            <label class="custom-control-label" for="customSwitch1"></label>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">App ID</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appId_Facebook"
            />
          </div>
        </div>
        <div class="row mb-3 row-input py-0">
          <label class="col-sm-2 font-weight-bold cpx-4">App Secret</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appSecret_Facebook"
            />
          </div>
        </div>
        <div class="row mb-3 mt-5 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            TWITTER
          </label>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">Enable</label>
          <div
            class="custom-control custom-switch col-sm-6 ml-4 cpx-4"
            style="height: 32px"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch2"
              role="switch"
              v-model="enable_Twitter"
            />
            <label class="custom-control-label" for="customSwitch2"></label>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4 pr-0">App ID</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appId_Twitter"
            />
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">App Secret</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appSecret_Twitter"
            />
          </div>
        </div>
        <div class="row mb-3 mt-5 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            LINE
          </label>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">Enable</label>
          <div
            class="custom-control custom-switch col-sm-6 ml-4"
            style="height: 32px"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch3"
              v-model="enable_Line"
            />
            <label class="custom-control-label" for="customSwitch3"></label>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">App ID</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appId_Line"
            />
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">App Secret</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appSecret_Line"
            />
          </div>
        </div>
        <div class="row mb-3 mt-5 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label">
            AMAZON
          </label>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">Enable</label>
          <div
            class="custom-control custom-switch col-sm-6 ml-4"
            style="height: 32px"
          >
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch4"
              v-model="enable_Amazon"
            />
            <label class="custom-control-label" for="customSwitch4"></label>
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">App ID</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appId_Amazon"
            />
          </div>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-2 font-weight-bold cpx-4">App Secret</label>
          <div class="col-sm-6 pl-0">
            <input
              class="form-input col-sm-8"
              type="text"
              v-model="appSecret_Amazon"
            />
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 py-4">
        <b-button
          v-on:click.prevent="CreateNewCategory()"
          :disabled="isLoading"
          class="mr-4 btn-color"
          variant="success"
          >変更を保存</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      enable_Facebook: true,
      appId_Facebook: "",
      appSecret_Facebook: "",
      enable_Twitter: true,
      appId_Twitter: "",
      appSecret_Twitter: "",
      enable_Line: true,
      appId_Line: "",
      appSecret_Line: "",
      enable_Amazon: true,
      appId_Amazon: "",
      appSecret_Amazon: "",
      id: null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      isLoading: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    const dataRequest = {
      shop_id: this.shop_id,
    };
    this.getDetailSNSSetting(dataRequest);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters(["SNSSetting", "success", "message", "error"]),
  },
  watch: {
    SNSSetting() {
      this.id = this.SNSSetting.id;
      if (this.SNSSetting.facebook_status === 1) {
        this.enable_Facebook = true;
      } else {
        this.enable_Facebook = false;
      }
      this.appId_Facebook = this.SNSSetting.facebook_app_id;
      this.appSecret_Facebook = this.SNSSetting.facebook_app_secret;
      if (this.SNSSetting.line_status === 1) {
        this.enable_Line = true;
      } else {
        this.enable_Line = false;
      }
      this.appId_Line = this.SNSSetting.line_app_id;
      this.appSecret_Line = this.SNSSetting.line_app_secret;
      if (this.SNSSetting.twitter_status === 1) {
        this.enable_Twitter = true;
      } else {
        this.enable_Twitter = false;
      }
      this.appId_Twitter = this.SNSSetting.twitter_app_id;
      this.appSecret_Twitter = this.SNSSetting.twitter_app_secret;
      if (this.SNSSetting.amazone_status === 1) {
        this.enable_Amazon = true;
      } else {
        this.enable_Amazon = false;
      }
      this.appId_Amazon = this.SNSSetting.amazone_app_id;
      this.appSecret_Amazon = this.SNSSetting.amazone_app_secret;
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        // this.$toasted.error(this.message);
        this.isLoading = false;
      }
    },
  },
  methods: {
    ...mapActions({ updateSNSSetting: "updateSNSSetting" }),
    ...mapActions({ getDetailSNSSetting: "getDetailSNSSetting" }),
    async CreateNewCategory() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      if (this.enable_Facebook) {
        this.enable_Facebook = 1;
      } else {
        this.enable_Facebook = 0;
      }
      if (this.enable_Line) {
        this.enable_Line = 1;
      } else {
        this.enable_Line = 0;
      }
      if (this.enable_Twitter) {
        this.enable_Twitter = 1;
      } else {
        this.enable_Twitter = 0;
      }
      if (this.enable_Amazon) {
        this.enable_Amazon = 1;
      } else {
        this.enable_Amazon = 0;
      }
      const formData = {
        id: this.id,
        shop_id: shop_id,
        facebook_status: this.enable_Facebook,
        facebook_app_id: this.appId_Facebook,
        facebook_app_secret: this.appSecret_Facebook,
        line_status: this.enable_Line,
        line_app_id: this.appId_Line,
        line_app_secret: this.appSecret_Line,
        twitter_status: this.enable_Twitter,
        twitter_app_id: this.appId_Twitter,
        twitter_app_secret: this.appSecret_Twitter,
        amazone_status: this.enable_Amazon,
        amazone_app_id: this.appId_Amazon,
        amazone_app_secret: this.appSecret_Amazon,
      };
      const dataReturn = await this.$store.dispatch(
        "updateSNSSetting",
        formData
      );
      if (dataReturn.success === true) {
        if (this.$route.params.shopId) {
          this.$router.push({
            name: "DashboardAdminSetting",
            params: { shopId: this.shop_id },
          });
        } else {
          this.$router.push({
            name: "DashboardAdminSetting domain",
          });
        }
      } else {
        this.$toasted.error(this.message);
        this.isLoading = false;
      }
    },
    returnList() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "DashboardAdminSetting",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "DashboardAdminSetting domain",
        });
      }
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin-top: 0px !important;
}
</style>
